import { useCallback } from 'react';
import { Types } from '@amplitude/analytics-browser';
import { useOnMount } from './useOnMount';
import { config } from '@/config/config';
import { amplitudeSingleton } from '@/config/amplitude';

const amplitude = amplitudeSingleton();

// https://www.docs.developers.amplitude.com/data/sdks/browser-2
export const amplitudeConfig: Types.BrowserOptions = {
  logLevel: Types.LogLevel.Warn,
  instanceName: config.environment.name,
  defaultTracking: {
    pageViews: false,
    sessions: false,
    attribution: false,
    fileDownloads: false,
    formInteractions: false,
  },
};

export const useAmplitudeInit = () => {
  const initAmplitude = useCallback(() => {
    amplitude.init(config.amplitude.key, amplitudeConfig);
  }, []);

  useOnMount(initAmplitude);
};
