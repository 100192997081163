'use client';
import type { AppProps } from 'next/app';
import { UserProvider } from '@auth0/nextjs-auth0/client';
import '@fontsource/inter';
import {
  Hydrate,
  QueryClient,
  QueryClientProvider,
} from '@tanstack/react-query';
import { useState } from 'react';
import ErrorBoundary from '@/components/ErrorBoundary';

import { useAmplitudeInit } from '@/hooks/useAmplitudeInit';
import ApplicationErrorFallback from '@/components/ApplicationErrorFallback';
import { trackErrorSentry } from '@/utils/trackErrorsSentry';
import { config } from '@/config/config';

export default function MyApp({ Component, pageProps }: AppProps) {
  useAmplitudeInit();

  const [queryClient] = useState(() => new QueryClient());
  const onErrorLogToSentry = (error: Error) => {
    if (config.environment.isProd || config.environment.isDev) {
      trackErrorSentry(error);
    }
  };

  return (
    <ErrorBoundary
      fallback={<ApplicationErrorFallback />}
      withErrorHandler={onErrorLogToSentry}
    >
      <QueryClientProvider client={queryClient}>
        <Hydrate state={pageProps.dehydratedState}>
          <UserProvider>
            <Component {...pageProps} />
          </UserProvider>
        </Hydrate>
      </QueryClientProvider>
    </ErrorBoundary>
  );
}
