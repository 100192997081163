import { Types, createInstance } from '@amplitude/analytics-browser';

let amplitudeInstance: Types.BrowserClient;

export const amplitudeSingleton = () => {
  if (!amplitudeInstance) {
    amplitudeInstance = createInstance();
  }

  return amplitudeInstance;
};
