import * as Sentry from '@sentry/nextjs';

export const trackErrorSentry = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  error: Error | any,
  metadata?: Record<string, string>
) => {
  if (metadata) {
    Sentry.captureException(error, {
      tags: metadata,
    });
  } else {
    Sentry.captureException(error);
  }
};
