import Stack from '@mui/joy/Stack';
import Typography from '@mui/joy/Typography';

export default function ApplicationErrorFallback() {
  return (
    <Stack
      alignItems="center"
      justifyContent="center"
      spacing={2}
      sx={{ height: '100vh', maxHeight: '100%', width: '100%' }}
    >
      <Typography level="h1">Ups</Typography>
      <Typography level="h4">Algo salió mal</Typography>
      <Typography level="body-md">
        Por favor refresca la página e intenta nuevamente.
      </Typography>
    </Stack>
  );
}
